@import 'styles/layers.scss';

@layer component {
  .wrap {
    height: 100%;
    margin-bottom: 30px;
    width: 277px;
    min-width: 277px; 
  }

  @media screen and (max-width: 1120px) {
    .wrap {
      display: none;
    }
  }
}