@import 'styles/layers.scss';

@layer component {
  .radio {
    margin-bottom: 30px;
  }

  .radio-title {
    opacity: 0.3;
    margin-bottom: 6px;
  }
}