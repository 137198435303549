@import 'styles/layers.scss';

@layer component {
  .content {
    width: 420px;
  }
  
  .title {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 300;
  }
}