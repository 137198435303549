@import 'styles/layers.scss';

@layer component {
  .content {
    row-gap: 20px;
  }

  .title {
    font-size: 14px;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
