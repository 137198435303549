@import 'styles/layers.scss';

@layer component {
  .dialog {
    min-width: 525px;
  }

  .body {
    padding: 22px;
  }

  .title {
    font-size: 20px;
    text-align: center;
    margin: 0;
  }

  .title-line {
    column-gap: 12px;
  }

  .text-line {
    margin: 16px 0 0;
    font-size: 12px;
    line-height: 190%;
  }

  .btn {
    padding: 10px 18px;
    width: 215px;
  }

  .block-settings {
    margin-top: 40px;
    row-gap: 40px;
  }

  .radio-group {
    row-gap: 9px;
    margin: 10px 0 17px;
  }

  .notification {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 17px 14px;
  }

  .notification-text {
    opacity: 0.3;
    font-size: 12px;
  }

  .icon {
    opacity: 0.3;
    margin-right: 10px;
  }

  .imgIcon {
    margin-right: 4px;
  }

  .link {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
    &:hover, &:active {
      color: inherit;
    }
  }

  .text {
    opacity: 0.3;
  }
}