@import 'styles/layers.scss';

@layer component {
  .wrap {
    height: 100%;
    padding-bottom: 22px;
  }

  .content {
    padding: 0 22px;
    max-height: 581px;
    overflow-y: auto;
  }

  .input-wrap {
    margin-bottom: 30px;
  }

  .text-area-input {
    height: 136px;
  }

  .title-wrap {
    margin-bottom: 30px;
  }

  .title {
    font-size: 20px;
  }

  .notification {
    margin-top: 30px;
  }

  .input-label {
    opacity: 0.3;
  }

  .link-notification {
    font-size: 12px;
  }

  .link-notification {
    color: white;
    text-decoration: underline;
  }

  .process-group {
    row-gap: 6px;
    display: flex;
    flex-direction: column;
  }

  .process-item {
    &:first-child {
      margin-top: 30px;
    }
  }

  .popover-text {
    width: 167px;
  }

  .info {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .icon-info {
    margin-right: 8px;
    color: var(--black38);
    border: 1px solid var(--black39);
    border-radius: 50%;
  }

  .upload-file {
    margin-top: 30px;
  }

  @supports (scrollbar-gutter: auto) {
      .content {
        padding-right: 16px;
        scrollbar-gutter: stable;
      }
      @-moz-document url-prefix() {
        .content {
          padding-right: 22px;
        }
      }
  }

  @media screen and (max-height: 743px) {
    .content {
      max-height: calc(100vh - 200px);
    }
  }  
}
