@import 'styles/layers.scss';

@layer component {
  .wrap {
    width: var(--max-width);
  }

  .spacer {
    height: 5px;
    width: 100%;
  }

  .content {
    padding-top: 25px;
    width: 100%;
  }
  
  .title {
    font-size: 22px;
    margin-bottom: 18px;
  }
  
  .td {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: unset;
  }
  
  .tr {
    user-select: none;
    cursor: pointer;
  }
  
  .table-header-wrapper {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .group {
    column-gap: 10px;
  }
  
  .table {
    min-width: 1250px;
    overflow-x: auto;
  }
  
  .dropdown-toggle {
    background-color: var(--black3);
    height: 40px;
    padding: 0 12px;
    color: var(--white);
  }
  
  .dropdown-menu {
    padding: 0;
    border-radius: 6px;
    width: 154px;
    min-width: 154px;
  }
  
  .dropdown-menu-item {
    height: 44px;
    width: 154px;
  }

  .dropdown-toggle-configurator {
    background-color: transparent;
  }
  
  .dropdown-menu-configurator {
    padding: 0;
    background-color: var(--black13);
    &:hover {
      background: var(--black13);
    }
    right: 0;
  }
}