@import 'styles/layers.scss';

@layer component {
  .group {
    width: 100%;
    padding: 14px;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid var(--black11);
    height: 68px;
    line-height: 160%;
  }

  .icon {
    margin-top: 4px;
    opacity: 0.3;
  }

  .icon-box {
    margin-right: 14px;
  }
}