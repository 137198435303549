@import 'styles/layers.scss';

@layer component {
  .block-data {
    row-gap: 8px;
    margin: 30px 0 16px;
  }
  .line {
    column-gap: 8px;
  }

  .label {
    color: var(--grey2);
  }

  .title {
    font-size: 20px;
  }
}