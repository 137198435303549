@import 'styles/layers.scss';

@layer component {
  .block {
    margin-bottom: 30px;
  }

  .radio-label {
    font-size: 12px;
  }

  .radio-title {
    margin-bottom: 10px;
  }
  .title-engine {
    max-width: 287px;
  }
}