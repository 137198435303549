@import 'styles/layers.scss';

@layer component {
  .wrap {
    flex: 1;
    height: 623px;
  }
  
  .inner {
    width: 400px;
  }
}