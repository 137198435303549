@import 'styles/layers.scss';

@layer component {
  .contaier {
    margin-top: 25px;
  }
  .wrap-title {
    margin-bottom: 25px;
    height: 42px;
    text-transform: capitalize;
  }

  .table-container {
    font-size: 14px;
  }
}