@import 'styles/layers.scss';

@layer component {
  .icon {
    color: var(--grey2);
  }

  .btn {
    height: 25px;
    padding: 6px 9px;
    font-size: 12px;
    border-radius: 6px;
    margin-right: 8px;
    &:hover:not(.disabled) {
      .icon {
        color: var(--grey2);
      }
    }
  }

  .text {
    opacity: 0.5;
  }

  .icon {
    margin-right: 5px;
  }

  .activeIcon {
    color: var(--white);
  }
}
