@import 'styles/layers.scss';

@layer component {
  .wrap {
    background-color: var(--black5);
    padding: 25px 20px;
    flex: 1;
    column-gap: 20px;
    width: 100%;
  }
  
  .all {
    border-radius: 4px;
  }
  
  .bottom {
    border-bottom: 4px;
  }
}