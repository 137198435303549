@import 'styles/layers.scss';

@layer uikit {
  .content {
    color: var(--white);
    border-radius: 4px;
    box-sizing: border-box;
    &.thin {
      width: 420px;
      background-color: var(--grey1);
      // border: 1px solid var(--grey1);
    }
    &.thick {
      width: 440px;
      background-color: var(--black5);
      // border: 1px solid var(--black5);
    }
    &.thick-active {
      width: 440px;
      background-color: var(--black5);
      // border: 1px solid var(--grey2);
    }
  }
  
  .main {
    row-gap: 10px;
    width: 100%;
  }
  
  .wrap-main {
    padding: 15px 15px 0;
  }
  
  .wrap-requirements {
    display: grid;
    grid-template-rows: 0fr;
    padding: 0 15px;
    overflow: hidden;
    margin: 8px 0 0;
    transition: grid-template-rows 0.3s ease-in-out, margin 0.3s ease-in-out;
    &.open {
      margin: 10px 0 17px;
      grid-template-rows: 1fr; 
    }
  }
    
  .tooltip {
    flex: 1;
    justify-content: flex-start !important;
  }
  
  .requirements {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 6px;
    column-gap: 10px;
    margin-top: 10px;
  }
  
  .content-inner {
    overflow: hidden;
  }
  
  .requirements-line {
    height: 30px;
    cursor: pointer;
  }
  
  .requirements-line:hover {
    background-color: var(--black3);
  }
  
  .requirements-button {
    row-gap: 10px;
    opacity: 0.3;
    font-size: 12px;
    &.openbtn {
      opacity: 1;
    }
  }
  
  .icon {
    margin-left: 10px;
    &.openicon {
      transform: rotate(180deg);
    }
  }
  
  .title-clickable {
    cursor: pointer;
  }
  
  .title-with-error {
    &.thin {
      max-width: 363px;
    }
    &.thick, &.thick-active {
      max-width: 383px;
    }
  }

  .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    ::before {
      content: "";
      position: absolute;
      height: 1px;
      top: 20px;
      left: 0;
      transform: scaleX(0);
      background-color: var(--white);
      transition: 0.3s all ease-in-out;
      width: 100%;
    }
    :hover::before {
      transform: scaleX(1);
    }
  }
  
  .info {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .infoLabel:nth-child(2n) {
    justify-self: end;
  }

  .icon-error {
    margin-right: 3px;
  }
}